* {
  box-sizing: border-box;
  outline: none;
}

html, body {
  font-family: "SF Pro Text", sans-serif;
  /*width: 100%;*/
  /*width: 2160px;*/
  /*height: 100vh;*/
  /*height: 3840px;*/
  margin: 0;
  padding: 0;
}
